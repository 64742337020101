import * as FullStory from "@fullstory/browser";
import posthog from "posthog-js";

type UserData = {
  user: UserType;
  company: CompanyType;
};

export const identifyUser = (userData: UserData) => {
  const { user, company } = userData;

  const userProperties = Object.entries(user).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`user_${key}`]: value,
    }),
    {}
  );

  const companyProperties = Object.entries(company).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`company_${key}`]: value,
    }),
    {}
  );

  posthog.identify(user.id as unknown as string, {
    ...userProperties,
    ...companyProperties,
  });

  // FullStory identification - only in production
  if (process.env.NEXT_PUBLIC_BUILD_ENV === "production") {
    FullStory.identify(user.id as unknown as string, {
      displayName: user.full_name,
      email: user.email,
    });
  }
};
